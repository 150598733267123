import styled from 'styled-components';

const VerificationContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
`;

const VerificationIconWrapper = styled.div`
  background: ${({ theme, isSelected }) => (isSelected ? '#f19c38' : theme.primaryWhite)};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.primaryTasks};
  border-radius: 4px;

  & img {
    width: 36px !important;
    height: 36px !important;
  }
`;

export { VerificationContainer, VerificationIconWrapper };
