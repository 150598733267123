import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';

import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import TextFieldAdapter from 'components/Forms/TextFieldAdapter';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import { Modal } from 'components/Modal';
import { TextInput } from 'components/Input';

import { RULE_DATA, RULES, RULE_INITIAL_VALUES } from 'helpers';
import { TASK_RULE_TYPES } from 'types/task';
import { UnitItemTaskTemplateType } from 'configs/propTypes';

import theme from 'theme';
import validate from './validator';
import * as S from './styled';

const AddEditTaskTemplateDialog = ({
  isOpen,
  title,
  handleClose,
  handleDelete,
  handleSaveContinue,
  withSaveContinue,
  task,
  onSubmit,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: task,
    validate,
    validateOnChange: true,
    onSubmit: values => {
      if (withSaveContinue) {
        handleSaveContinue();
      }
      onSubmit(values);
      formik.resetForm();
    },
  });

  const isTaskEmpty = isEmpty(task);

  const onChangeHandler = (value, name) => formik.setFieldValue(name, value);

  const onRequestClose = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <Modal isOpen={isOpen} title={title} titleColor={theme.headerColor} onRequestClose={onRequestClose}>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.AddEditForm>
          <SelectFieldAdapter
            floatingLabelText="Task Type"
            name="RuleType"
            input={formik.getFieldProps('RuleType')}
            meta={formik.getFieldMeta('RuleType')}
            onChangeControl={value => onChangeHandler(value, 'RuleType')}
          >
            {RULES.map(rule => (
              <MenuItem key={rule.value} {...rule} />
            ))}
          </SelectFieldAdapter>
          <S.Instructions>
            <S.InstructionsLabel>Instructions</S.InstructionsLabel>
            <S.InputContainer>
              <TextFieldAdapter
                floatingLabelText="Short Instruction or Question"
                name="Name"
                maxLength="255"
                input={formik.getFieldProps('Name')}
                meta={formik.getFieldMeta('Name')}
                onChangeControl={value => onChangeHandler(value, 'Name')}
              />
              <TextFieldAdapter
                floatingLabelText="Task Notes / Additional Instructions"
                name="InstructionalNotes"
                input={formik.getFieldProps('InstructionalNotes')}
                meta={formik.getFieldMeta('InstructionalNotes')}
                onChangeControl={value => onChangeHandler(value, 'InstructionalNotes')}
              />
            </S.InputContainer>
          </S.Instructions>
          {!!RULE_DATA[formik.values.RuleType]?.length && (
            <S.RuleData>
              <S.RuleDataLabel>Rule Data</S.RuleDataLabel>
              {formik.values.RuleType === TASK_RULE_TYPES.REPORT_COMPLETION && (
                <S.Wrapper>
                  <TextInput
                    input={formik.getFieldProps('RuleData.Specification')}
                    meta={formik.getFieldMeta('RuleData.Specification')}
                    type="text"
                    onChange={e => onChangeHandler(e.currentTarget.value, 'RuleData.Specification')}
                    placeholder="UOM"
                    name="RuleData.Specification"
                    inputStyles={formik.errors?.RuleData?.Specification && S.inputErrorStyles}
                    inputWrapperStyles={formik.errors?.RuleData?.Specification && S.inputWrapperErrorStyles}
                  />
                  <p>Unit of Measure (time/volume/size)</p>
                </S.Wrapper>
              )}
              <S.InputContainer>
                {RULE_DATA[formik.values.RuleType]?.map(item => (
                  <TextFieldAdapter
                    key={item.name}
                    input={formik.getFieldProps(item.name)}
                    meta={formik.getFieldMeta(item.name)}
                    onChangeControl={value => onChangeHandler(value, item.name)}
                    {...item}
                  />
                ))}
              </S.InputContainer>
              {(formik.values.RuleType === TASK_RULE_TYPES.REPORT_COMPLETION ||
                formik.values.RuleType === TASK_RULE_TYPES.REPORT_PROGRESS) && (
                <S.Wrapper>
                  <S.CheckBox />
                  <TextInput
                    type="number"
                    input={formik.getFieldProps('RuleData.Point')}
                    meta={formik.getFieldMeta('RuleData.Point')}
                    onChange={e => onChangeHandler(e.currentTarget.value, 'RuleData.Point')}
                    name="RuleData.Point"
                    inputStyles={S.inputStyles}
                    inputWrapperStyles={S.inputWrapperStyles}
                    placeholder="Set Point (Default 50%)"
                  />
                </S.Wrapper>
              )}
            </S.RuleData>
          )}
        </S.AddEditForm>
        <ActionsContainer isColumn={isTaskEmpty} gapValue={isTaskEmpty ? 10 : 20}>
          {isTaskEmpty ? (
            <ActionButton type="submit" label="Save and Add a Task" onClick={handleSaveContinue} isNext isGreenLabel />
          ) : (
            <ActionButton type="button" label="Delete" onClick={handleDelete} />
          )}
          <ActionButton type="submit" label="Save" isNext isGreenLabel withGreenBorder={isTaskEmpty} />
        </ActionsContainer>
      </S.Form>
    </Modal>
  );
};

AddEditTaskTemplateDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSaveContinue: PropTypes.func,
  withSaveContinue: PropTypes.bool,
  task: UnitItemTaskTemplateType,
  onSubmit: PropTypes.func.isRequired,
};

AddEditTaskTemplateDialog.defaultProps = {
  withSaveContinue: PropTypes.bool,
  handleSaveContinue: null,
  task: {
    InstructionalNotes: '',
    Name: '',
    ...RULE_INITIAL_VALUES,
  },
};

export default AddEditTaskTemplateDialog;
