import React from 'react';
import { PropTypes } from 'prop-types';
import { DislikeIcon, LikeIcon } from 'components/Layout/Icons';

import * as S from './styled';

export const ThumbRating = ({ formik: { setFieldValue }, name, isSelected }) => (
  <S.VerificationContainer>
    <S.VerificationIconWrapper isSelected={isSelected} onClick={() => setFieldValue(name, true)}>
      <LikeIcon />
    </S.VerificationIconWrapper>
    <S.VerificationIconWrapper isSelected={isSelected === false} onClick={() => setFieldValue(name, false)}>
      <DislikeIcon />
    </S.VerificationIconWrapper>
  </S.VerificationContainer>
);

ThumbRating.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
