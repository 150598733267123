import React from 'react';
import { PropTypes } from 'prop-types';
import { TextInput } from 'components/Input';
import { MuiThemeProvider } from 'material-ui/styles';
import * as S from './styled';

const INITIAL_VALUE = 0;

export const RangeSliderTextInput = ({ formik, minValue, maxValue, value, name, slot }) => {
  const { getFieldProps, getFieldMeta, errors, setFieldValue } = formik;

  return (
    <>
      {slot}
      <TextInput
        type="number"
        {...getFieldProps(name)}
        meta={getFieldMeta(name)}
        name={name}
        inputWrapperStyles={errors[name] ? S.inputWrapperStyles : []}
        inputStyles={[...S.inputStyles, ...(errors[name] ? S.errorStyles : [])]}
      />
      <S.SliderContainer>
        <div>{minValue}</div>
        <MuiThemeProvider muiTheme={S.muiTheme}>
          <S.RangeSlider
            onChange={(e, v) => setFieldValue(name, v)}
            value={value ?? INITIAL_VALUE}
            name={name}
            min={minValue ?? INITIAL_VALUE}
            max={maxValue ?? INITIAL_VALUE}
          />
        </MuiThemeProvider>
        <div>{maxValue}</div>
      </S.SliderContainer>
    </>
  );
};

RangeSliderTextInput.propTypes = {
  formik: PropTypes.object.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  slot: PropTypes.node,
};

RangeSliderTextInput.defaultProps = {
  slot: null,
};
