import styled from 'styled-components';
import { ButtonFlat } from 'components/Layout/Buttons';

const CancelButton = styled(ButtonFlat).attrs({
  label: 'Cancel',
})`
  min-width: 50% !important;

  span {
    color: ${({ theme }) => theme.modalCancelColor} !important;
  }
`;

const RootContainer = styled.div`
  padding: 10px;
`;

const DownloadButton = styled(ButtonFlat).attrs({
  label: 'Download',
})`
  min-width: 50% !important;
  border-left: 1px solid ${({ theme }) => theme.lightGrey} !important;

  span {
    color: ${({ theme }) => theme.greenButton} !important;
  }
`;

const Title = styled.p`
  margin: 20px 0 0 0;
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.3px;
`;

const RedTitle = styled.p`
  margin: 10px 0 0 0;
  color: ${({ theme }) => theme.mainRed};
  text-align: center;
  font-weight: 900;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TextDate = styled.p`
  color: ${({ theme }) => theme.primaryBlack};
  margin: 20px 25px 20px 30px;
  font-size: 16px;
  letter-spacing: 0.3px;
`;

export { CancelButton, RootContainer, DownloadButton, Title, RedTitle, DateContainer, TextDate };
