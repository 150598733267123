/* eslint-disable react/prop-types */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/reducers';
import NiceModal from '@ebay/nice-modal-react';
import ConfirmationModal from 'components/Dialogs/ConfirmationModal';
import { actions as tasksActions } from 'redux/tasks';
import { actions as sensorsActions } from 'redux/sensors';
import { Textarea } from 'components/Textarea';
import { PhotosSlider } from '../PhotosSlider';
import { PdfsDialog } from '../PdfsDialog';
import { actions as healthActions } from 'redux/health';
import ItemSensorsDialog from 'components/Dialogs/ItemSensorsDialog';
import { TaskType, TASK_RULE_TYPES } from 'types/task';

import { RangeSliderTextInput } from './RangeSliderTextInput';
import { ThumbRating } from './ThumbRating';

import * as S from './styled';

const RULE_TYPE_TITLE: Record<string, string> = {
  [TASK_RULE_TYPES.FEEDBACK]: 'FEEDBACK',
  [TASK_RULE_TYPES.VERIFICATION]: 'VERIFICATION',
  [TASK_RULE_TYPES.REPLACE]: 'REPLACE',
  [TASK_RULE_TYPES.SCAN_CODE]: 'SCAN QR',
  [TASK_RULE_TYPES.PHOTO_CONFIRMATION]: 'PHOTO',
  [TASK_RULE_TYPES.CHECK_QUANTITY]: 'CHECK QUANTITY',
  [TASK_RULE_TYPES.CHECK_TEMPERATURE]: 'CHECK TEMPERATURE',
  [TASK_RULE_TYPES.CHECK_FLOW_RATE]: 'CHECK FLOWRATE',
  [TASK_RULE_TYPES.REPORT_COMPLETION]: 'COMPLETED',
  [TASK_RULE_TYPES.REPORT_PROGRESS]: '% PROGRESS',
  [TASK_RULE_TYPES.SIGNATURE]: 'SIGNATURE',
};

type PropsType = {
  task: TaskType;
  // formik: FormikProps<FormValuesType>;
  formik: any;
};

export const MainInfo: FC<PropsType> = React.memo(({ task, formik }) => {
  const { values, setFieldValue, getFieldMeta, getFieldProps, errors } = formik;
  const [isPhotosShown, setIsPhotosShown] = useState(true);
  const [isItemSensorsDialogOpened, setIsItemSensorsDialogOpened] = useState(false);
  const [alertTrendsVisible, setAlertTrendsVisible] = useState<Record<string, boolean>>({});
  const taskPhotos = useAppSelector(state => state.tasks.taskPhotos);

  const dispatch = useDispatch();

  const openItemSensorsDialog = () => setIsItemSensorsDialogOpened(true);
  const closeItemSensorsDialog = () => setIsItemSensorsDialogOpened(false);

  const handlePhotoConfirmation = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setFieldValue('photoConfirmation', event.target.files[0]);
      dispatch(tasksActions.uploadPhotoTasksRequest({ file: event.target.files[0], parentId: task.Id }));
    }
  };

  const openConfirmationModal = () =>
    NiceModal.show(ConfirmationModal, {
      label: 'CONTINUE',
      isNext: true,
      isGreenLabel: true,
      isCancelNext: false,
      labelFontSize: '18px',
      labelCancel: 'Make default',
      onClick: () => {
        setIsPhotosShown(false);
        NiceModal.hide(ConfirmationModal);
      },
      onCancelClick: () => {
        setIsPhotosShown(true);
        NiceModal.hide(ConfirmationModal);
      },
      maxWidth: '600px',
      text: (
        <S.WarningText>
          Default this to <b>hiding</b> photos?
        </S.WarningText>
      ),
    });

  const openFeatureNotActivatedModal = () =>
    NiceModal.show(ConfirmationModal, {
      label: 'CANCEL',
      isNext: true,
      isGreenLabel: true,
      onClick: () => NiceModal.hide(ConfirmationModal),
      text: (
        <S.WarningText>
          This feature is currently <b>not activated</b>. <br />
          Please contact the Incheq team for <br /> additional information, or use the <br /> Mobile <b>TaskApp</b> to
          complete this <br />
          task
        </S.WarningText>
      ),
    });

  // eslint-disable-next-line no-shadow
  const openAlertTrends = (id: string, alertTrendsVisible: Record<string, boolean>, status?: string) => {
    setAlertTrendsVisible({
      [id]: status ? !alertTrendsVisible[id] : false,
    });
  };

  const showAlertTrends = async (id: string, status: string) => {
    if (alertTrendsVisible[id] || !status) {
      openAlertTrends(id, alertTrendsVisible);
    } else {
      dispatch(
        healthActions.getAlertTrendsRequest({
          id,
          alertTrendsVisible,
          openAlertTrends,
          status,
        }),
      );
    }
  };

  const renderRuleTypeComponent = () => {
    switch (task.RuleType) {
      case TASK_RULE_TYPES.FEEDBACK:
        return (
          <Textarea
            {...getFieldProps('feedback')}
            name="feedback"
            styles={errors.feedback ? S.errorStyles : S.textareaStyles}
            meta={getFieldMeta('feedback')}
          />
        );
      case TASK_RULE_TYPES.VERIFICATION:
      case TASK_RULE_TYPES.REPLACE:
        return <ThumbRating formik={formik} name="verificationResponse" isSelected={values.verificationResponse} />;
      case TASK_RULE_TYPES.SCAN_CODE:
        return (
          <S.Button type="button" onClick={openFeatureNotActivatedModal}>
            Scan Code
          </S.Button>
        );
      case TASK_RULE_TYPES.PHOTO_CONFIRMATION: {
        const error = errors.photoConfirmation;

        return (
          <>
            <S.PhotoInfoContainer>
              <div>Actual Qty: {task.RuleData.CurrentPhotos}</div>
              <S.Value>
                Min {task.RuleData.MinPhotos} Max {task.RuleData.MaxPhotos}
              </S.Value>
            </S.PhotoInfoContainer>
            <S.ButtonContainer>
              <input type="file" id="choose-photo" hidden name="photoConfirmation" onChange={handlePhotoConfirmation} />
              <S.Label htmlFor="choose-photo">Make Selection</S.Label>
            </S.ButtonContainer>

            <S.Message error={error}>{error || values.photoConfirmation.name}</S.Message>
          </>
        );
      }
      case TASK_RULE_TYPES.CHECK_QUANTITY:
        return (
          <RangeSliderTextInput
            formik={formik}
            minValue={task.RuleData.MinQty}
            maxValue={task.RuleData.MaxQty}
            value={values.actualQty}
            name="actualQty"
          />
        );
      case TASK_RULE_TYPES.CHECK_TEMPERATURE:
        return (
          <RangeSliderTextInput
            formik={formik}
            minValue={task.RuleData.MinTemperature}
            maxValue={task.RuleData.MaxTemperature}
            value={values.actualTemperature}
            name="actualTemperature"
          />
        );
      case TASK_RULE_TYPES.CHECK_FLOW_RATE:
        return (
          <RangeSliderTextInput
            formik={formik}
            minValue={task.RuleData.MinFlow}
            maxValue={task.RuleData.MaxFlow}
            value={values.actualFlow}
            name="actualFlow"
          />
        );

      case TASK_RULE_TYPES.REPORT_COMPLETION:
        return (
          <RangeSliderTextInput
            formik={formik}
            minValue={task.RuleData.MinFlow}
            maxValue={task.RuleData.MaxFlow}
            value={values.actualFlow}
            name="actualFlow"
            slot={<S.UnitOfMeasure>{task.RuleData.UnitOfMeasure}</S.UnitOfMeasure>}
          />
        );

      case TASK_RULE_TYPES.REPORT_PROGRESS:
        return (
          <RangeSliderTextInput
            formik={formik}
            minValue={task.RuleData.MinFlow}
            maxValue={task.RuleData.MaxFlow}
            value={values.actualFlow}
            name="actualFlow"
          />
        );
      case TASK_RULE_TYPES.SIGNATURE:
        return (
          <S.Button type="button" onClick={openFeatureNotActivatedModal}>
            Open
          </S.Button>
        );
      default:
        return <span>No rule Type</span>;
    }
  };

  const openPdfsDialog = () => NiceModal.show(PdfsDialog, { itemId: task.ItemId, type: 'item' });

  useEffect(() => {
    dispatch(tasksActions.fetchPhotosRequest(task.ItemId));
    dispatch(sensorsActions.getItemSensorsRequest(task.ItemId));
  }, [dispatch, task.ItemId]);

  return (
    <S.Main>
      <S.ItemName>{task.ItemName}</S.ItemName>
      <S.Wrapper isPhotosShown={isPhotosShown} onClick={openItemSensorsDialog} status={task.StatusSensor}>
        <S.AlertIcon />
      </S.Wrapper>
      <S.IconButtonContainer>
        <S.IconButton label="PDF's" labelPosition="after" icon={<S.PDFIcon />} onClick={openPdfsDialog} />
      </S.IconButtonContainer>
      <S.InfoContainer>
        <S.ToggleContainer>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <S.VisibilityIconButton
            label={`${isPhotosShown ? 'Hide' : 'Show'} photos`}
            labelPosition="after"
            icon={isPhotosShown ? <S.ChevronDownIcon /> : <S.ChevronUpIcon />}
            onClick={openConfirmationModal}
          />
        </S.ToggleContainer>
        {isPhotosShown && <PhotosSlider type="task" photos={taskPhotos} />}
        <S.RedText>{task.OriginatingTaskName}</S.RedText>
        <S.TaskNotesContainer>
          <S.Name>TASK NOTES</S.Name>
          <S.Note>{task.InstructionalNotes}</S.Note>
        </S.TaskNotesContainer>
      </S.InfoContainer>
      <S.RuleDataContainer>
        <p>{RULE_TYPE_TITLE[task.RuleType]}</p>
        {renderRuleTypeComponent()}
      </S.RuleDataContainer>
      <ItemSensorsDialog
        handleClose={closeItemSensorsDialog}
        open={isItemSensorsDialogOpened}
        title={`Item Name: ${task.ItemName}`}
        status={task.StatusSensor}
        alertTrendsVisible={alertTrendsVisible}
        showAlertTrends={showAlertTrends}
      />
    </S.Main>
  );
});
