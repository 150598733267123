import React from 'react';
import PropTypes from 'prop-types';

import { getTaskName } from 'helpers';
import { UnitItemTaskTemplateType } from 'configs/propTypes';
import { AddIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import Toggle from 'components/Toggle';
import { ToolbarContainer } from '../../styled';
import TableList from 'components/TableList';

import { itemDetailsToggle } from 'configs/toggles';
import { tableConfigs, tableHeads } from './configs';

import * as S from './styled';

const Tasks = ({ itemId, onAddEditTaskTemplateClick, tasks, type, typeHandler, onCopyTask, searchData }) => {
  const filterList = item => item?.Name.toLowerCase().includes(searchData.toLowerCase());

  const mapList = (item, index, arr) => {
    const lastItem = index + 1 === arr.length;

    const overridePosition = ({ left, top }, event, triggerElement, tooltipElement) => ({
      top,
      left: Math.min(left, window.innerWidth - tooltipElement.offsetWidth),
    });

    const onDuplicateIconClick = e => {
      e.stopPropagation();
      onCopyTask(item);
    };

    return item.Id
      ? {
          ...item,
          Duplicate: (
            <ButtonIcon tooltip="Duplicate" onClick={onDuplicateIconClick}>
              <S.IconCopy />
            </ButtonIcon>
          ),
          TasksAndInstructions: <S.BoldText>{getTaskName('', item.RuleType)}</S.BoldText>,
          Min: item.RuleData.MinAttachments === null ? item.RuleData.ExpectedLowerBound : item.RuleData.MinAttachments,
          Max: item.RuleData.MaxAttachments === null ? item.RuleData.ExpectedUpperBound : item.RuleData.MaxAttachments,
          Notes: (
            <>
              <ButtonIcon>
                <S.NotesIcon data-tip data-for={item.Id} />
              </ButtonIcon>
              <S.Tooltip
                id={item.Id}
                place={lastItem ? 'top' : 'bottom'}
                effect="solid"
                overridePosition={overridePosition}
              >
                <S.TooltipBlock>
                  <S.WhiteNotesIcon />
                  <S.NotesTooltipBlock>
                    <S.TooltipTitle>Task Notes/Additional Instructions</S.TooltipTitle>
                    <S.TooltipText>{item.InstructionalNotes}</S.TooltipText>
                  </S.NotesTooltipBlock>
                </S.TooltipBlock>
              </S.Tooltip>
            </>
          ),
        }
      : item;
  };

  const onRowClick = task => {
    onAddEditTaskTemplateClick(itemId, task);
  };

  return (
    <>
      <ToolbarContainer>
        <Toggle config={itemDetailsToggle} selected={type.toLowerCase()} handler={typeHandler} minWidth />
        <ButtonIcon onClick={() => onAddEditTaskTemplateClick(itemId, {})}>
          <AddIcon />
        </ButtonIcon>
      </ToolbarContainer>
      <S.Container>
        {!tasks?.length ? (
          <S.EmptyList>
            <S.Message>This item has no tasks.</S.Message>
          </S.EmptyList>
        ) : (
          <S.TableContainer>
            <TableList
              isPointer
              list={tasks.map(mapList).filter(filterList)}
              tableHeads={tableHeads}
              tableColumns={tableConfigs}
              onRowClick={onRowClick}
            />
          </S.TableContainer>
        )}
      </S.Container>
    </>
  );
};

Tasks.propTypes = {
  itemId: PropTypes.string.isRequired,
  onAddEditTaskTemplateClick: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(UnitItemTaskTemplateType).isRequired,
  onCopyTask: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  typeHandler: PropTypes.func.isRequired,
  searchData: PropTypes.string.isRequired,
};

Tasks.defaultProps = {
  itemId: '',
};

export default Tasks;
