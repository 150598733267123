import styled from 'styled-components';
import Modal from 'material-ui/Dialog';

import { ButtonFlat } from 'components/Layout/Buttons';

export const MaterialDialog = styled(Modal).attrs({
  actionsContainerStyle: {
    borderTop: ({ theme }) => `1px solid ${theme.lightGrey}`,
    padding: '3px',
    paddingBottom: '2px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bodyStyle: {
    overflow: 'auto',
    padding: '0px',
  },
  titleStyle: {
    borderBottom: ({ theme }) => `1px solid ${theme.lightGrey}`,
  },
  contentStyle: ({ modalStyles }) => modalStyles,
})`
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 1900px),
    only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 1900px),
    only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 1900px),
    only screen and (min-device-pixel-ratio: 2) and (min-width: 1900px),
    only screen and (min-resolution: 192dpi) and (min-width: 1900px),
    only screen and (min-resolution: 2dppx) and (min-width: 1900px) {
    padding-top: 12px !important;
  }

  & > div {
    & > div {
      width: ${({ width }) => width && width} !important;
      max-width: ${({ maxWidth }) => maxWidth && maxWidth} !important;
      z-index: ${({ zIndex }) => zIndex}!important;
    }
  }

  z-index: ${({ zIndex }) => zIndex}!important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  padding: ${({ paddingValue }) => (paddingValue ? `10px ${paddingValue}px` : '10px 32px')} !important;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')} !important;
  gap: ${({ gapValue }) => gapValue && `${gapValue}px`} !important;

  button {
    margin: ${({ isColumn }) => isColumn && '10px 0'} !important;
    margin: ${({ marginValue }) => marginValue && `${marginValue}px 0`} !important;
  }
`;

export const StyledButton = styled(ButtonFlat).attrs({
  fontSize: ({ labelFontSize }) => labelFontSize,
})`
  width: 100% !important;
  background-color: ${({ theme, isNext }) => (isNext ? theme.lightGreenButton : theme.secondaryRed)} !important;
  background-color: ${({ theme, isCancelNext }) => isCancelNext && theme.lightGreenButton} !important;
  border: ${({ theme, withBorder, isDisable }) => !isDisable && withBorder && `2px solid ${theme.mainRed} !important`};
  border: ${({ theme, withGreenBorder, isDisable }) =>
    !isDisable && withGreenBorder && `2px solid ${theme.greenButton} !important`};
  border: ${({ theme, withBorder, isDisable }) => !isDisable && withBorder && `2px solid ${theme.mainRed} !important`};
  background-color: ${({ theme, withBorder, withGreenBorder }) =>
    (withBorder || withGreenBorder) && theme.primaryWhite} !important;
  background-color: ${({ theme, isDisable }) => isDisable && theme.lightGrey} !important;
  border-radius: 6px !important;

  span {
    color: ${({ theme, isNext }) => (isNext ? theme.mainGreen : theme.redButton)} !important;
    color: ${({ theme, withBorder }) => withBorder && theme.mainRed} !important;
    color: ${({ theme, isGreenLabel }) => isGreenLabel && theme.greenButton} !important;
    color: ${({ theme, isCancelNext }) => isCancelNext && theme.mainGreen} !important;
    color: ${({ theme, isDisable }) => isDisable && theme.textGray} !important;
  }
`;
