/* eslint-disable no-shadow */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { UnitItemType } from 'configs/propTypes';
import { unitDetailsToggle } from 'configs/toggles';
import { resizeWindow } from 'helpers';

import { AddIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import LevelSettingsDialog from 'components/Dialogs/LevelSettingsDialog';
import PreviewDuplicateDialog from 'components/Dialogs/DuplicateDialog';
import Toggle from 'components/Toggle';

import { actions as sensorsActions } from 'redux/sensors';
import { actions as unitActions } from 'redux/unit';

import { ToolbarContainer } from '../styled';
import AddEditItemDialog from './AddEditItemDialog';
import AddEditTaskTemplateDialog from './AddEditTaskTemplateDialog';
import DuplicateDialog from './DuplicateDialog';
import SensorsDialog from './SensorsDialog';
import LinkSensorDialog from './LinkSensorDialog';
import Item from './Item';

import * as S from './styled';

const ItemsList = ({
  items,
  typeHandler,
  type,
  match: {
    params: { unitId },
  },
  history,
}) => {
  const [addEditItemDialogOpened, setAddEditItemDialogOpened] = useState(false);
  const [addEditTaskTemplateDialogOpened, setAddEditTaskTemplateDialogOpened] = useState(false);

  const [duplicateDialogOpened, setDuplicateDialogOpened] = useState(false);
  const [sensorsDialogOpened, setSensorsDialogOpened] = useState(false);
  const [levelSettingsDialogOpened, setLevelSettingsDialogOpened] = useState(false);
  const [isPreviewDuplicateDialogVisible, setIsPreviewDuplicateDialogVisible] = useState(false);
  const [linkSensorDialogOpened, setLinkSensorDialogOpened] = useState(false);
  const [saveAndAddTaskSelected, setSaveAndAddTaskSelected] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});
  const [selectedSensor, setSelectedSensor] = useState({});
  const [itemToDuplicate, setItemToDuplicate] = useState(null);
  const [itemToEdit, setItemToEdit] = useState(undefined);
  const [selectedSensorId, setSelectedSensorId] = useState('');
  const [isLevelsSaveAndSet, setIsLevelsSaveAndSet] = useState(false);

  const userType = useSelector(state => state.auth.user.userType);
  const createdEditedItem = useSelector(state => state.unit.createdEditedItem);
  const createdEditedTask = useSelector(state => state.unit.createdEditedTask);
  const createdItemId = useSelector(state => state.unit.createdItemId);

  const sensorsForSite = useSelector(state => state.sensors.sensorsForSite);
  const typesList = useSelector(state => state.sensors.typesList);
  const isEditSensorLoading = useSelector(state => state.sensors.isEditSensorLoading);
  const error = useSelector(state => state.sensors.error);
  const dispatch = useDispatch();

  const handleSaveAndSetLevels = () => setIsLevelsSaveAndSet(true);

  const openPreviewDuplicateDialog = itemToDuplicate => {
    setIsPreviewDuplicateDialogVisible(true);
    setItemToDuplicate(itemToDuplicate);
  };

  const closePreviewDuplicateDialog = () => setIsPreviewDuplicateDialogVisible(false);

  const openAddEditTaskTemplateDialogOnModalClose = useCallback(() => {
    if (saveAndAddTaskSelected) {
      setTimeout(() => {
        // need time for modal animations
        setSaveAndAddTaskSelected(false);
        setAddEditTaskTemplateDialogOpened(true);
      }, 500);
    }
  }, [saveAndAddTaskSelected]);

  const closeAddEditItemDialog = useCallback(() => {
    setAddEditItemDialogOpened(false);
    openAddEditTaskTemplateDialogOnModalClose();
  }, [openAddEditTaskTemplateDialogOnModalClose]);

  const closeAddEditTaskTemplateDialog = useCallback(() => {
    setAddEditTaskTemplateDialogOpened(false);
    openAddEditTaskTemplateDialogOnModalClose();
  }, [openAddEditTaskTemplateDialogOnModalClose]);

  const openDuplicateDialog = () => {
    setDuplicateDialogOpened(true);
    closePreviewDuplicateDialog();
  };

  const closeDuplicateDialog = () => {
    setDuplicateDialogOpened(false);
    setItemToDuplicate(null);
  };

  const closeSensorsDialog = () => {
    dispatch(unitActions.fetchUnitItemsRequest({ unitId }));
    setSensorsDialogOpened(false);
  };

  const closeLinkSensorDialog = () => setLinkSensorDialogOpened(false);

  const closeLevelSettingsDialog = () => {
    setLevelSettingsDialogOpened(false);
    setSelectedSensor({});
  };

  const duplicateItem = itemData => {
    closeDuplicateDialog();
    dispatch(unitActions.duplicateUnitItemRequest(itemData));
    history.push(`/units/items/${itemData.UnitId}`);
  };

  const getSelectedSensorId = selectedSensorId => setSelectedSensorId(selectedSensorId);

  const openAddEditItemDialog = item => {
    setAddEditItemDialogOpened(true);
    if (item.Id) {
      setItemToEdit(item);
    } else {
      setItemToEdit(undefined);
    }
  };

  const openSensorsDialog = item => {
    dispatch(sensorsActions.getItemSensorsRequest(item.Id));
    dispatch(sensorsActions.getSensorsForSiteRequest());
    dispatch(sensorsActions.getSensorTypesRequest());

    setSensorsDialogOpened(true);
    setSelectedItem(item);
  };

  const openLinkSensorDialog = () => setLinkSensorDialogOpened(true);

  const openLevelSettingsDialog = useCallback(
    sensor => {
      dispatch(sensorsActions.getLevelsForItemSensorRequest({ sensor, showLevelSettingsDialog }));
    },
    [dispatch],
  );

  const showLevelSettingsDialog = selectedSensor => {
    setLevelSettingsDialogOpened(true);
    setSelectedSensor(selectedSensor);
  };

  const renderSensorsDialogTitle = () => (
    <S.Title>
      <S.ItemName>{selectedItem.Name}</S.ItemName>
      <div>Sensors</div>
    </S.Title>
  );

  const submitLinkSensorDialog = values => {
    const selectedSensor = sensorsForSite.find(item => item.Id === values.SensorID);
    const LevelsCount = typesList.find(type => type.Id === values.SensorTypeID).LevelsCount;
    const { Id, UnitId } = selectedItem;
    const payload = {
      itemId: Id,
      values: {
        id: values.SensorID,
        dto: {
          ...selectedSensor,
          ItemId: Id,
          IsAssigned: true,
          LevelsCount,
          SensorTypeID: values.SensorTypeID,
          UnitId,
        },
      },
    };

    dispatch(sensorsActions.editSensorRequest(payload));

    closeLinkSensorDialog();

    setTimeout(() => resizeWindow(), 0);
  };

  const submitLevelSettingsDialog = values => {
    const newValues = [];

    Object.keys(values).forEach(val => {
      if (val.endsWith('StartData')) {
        const id = val.slice(0, -9);

        const level = {
          LevelName: values[`${id}LevelName`],
          MasterSensorID: selectedSensor.Id,
          MasterSensorTypeId: values.MasterSensorTypeId,
          OrderField: values[`${id}OrderField`],
          StartData: values[val],
          ShouldSendAlert: values[`${id}ShouldSendAlert`],
        };

        if (values.escalation === 'both' && level.LevelName === 'Normal') {
          level.StartData = null;
        }

        if (values[`${id}Original`]) {
          level.Id = id;
        }

        newValues.push(level);
      }
    });

    dispatch(
      sensorsActions.setLevelsForItemSensorRequest({
        itemId: selectedItem.Id,
        newValues,
        sensorId: selectedSensor.Id,
      }),
    );
    closeLevelSettingsDialog();
  };

  const addTask = taskData => {
    dispatch(unitActions.addUnitTaskRequest({ itemId: createdItemId, taskData }));
  };

  const handleSaveAndAddTask = () => {
    setSaveAndAddTaskSelected(true);
  };

  useEffect(() => {
    if (!isEditSensorLoading && isLevelsSaveAndSet) {
      const selectedSensor = sensorsForSite.find(item => item.Id === selectedSensorId);
      if (!linkSensorDialogOpened && !error) {
        openLevelSettingsDialog(selectedSensor);
      }
      setIsLevelsSaveAndSet(false);
    }
  }, [
    error,
    isEditSensorLoading,
    isLevelsSaveAndSet,
    linkSensorDialogOpened,
    openLevelSettingsDialog,
    selectedSensorId,
    sensorsForSite,
  ]);

  useEffect(() => {
    if (createdEditedTask) {
      closeAddEditTaskTemplateDialog();
      dispatch(unitActions.clearAddedTask());
    }
  }, [createdEditedTask, closeAddEditTaskTemplateDialog, dispatch]);

  useEffect(() => {
    if (createdEditedItem) {
      dispatch(unitActions.clearItemAdded());
      closeAddEditItemDialog();
    }
  }, [closeAddEditItemDialog, createdEditedItem, dispatch]);

  return [
    <S.Container key="Items list">
      <ToolbarContainer>
        <Toggle config={unitDetailsToggle} selected={type.toLowerCase()} handler={typeHandler} minWidth />
        <ButtonIcon onClick={openAddEditItemDialog}>
          <AddIcon />
        </ButtonIcon>
      </ToolbarContainer>
      {items.length ? (
        items.map(item => (
          <Item
            key={item.Id}
            item={item}
            onClickItem={openAddEditItemDialog}
            onDuplicateItemClick={openPreviewDuplicateDialog}
            openSensorsDialog={openSensorsDialog}
            userType={userType}
          />
        ))
      ) : (
        <S.Message>This unit has no items.</S.Message>
      )}
    </S.Container>,
    <AddEditItemDialog
      key="Add edit item dialog"
      handleClose={closeAddEditItemDialog}
      open={addEditItemDialogOpened}
      item={itemToEdit}
      handleSaveContinue={handleSaveAndAddTask}
    />,
    <AddEditTaskTemplateDialog
      key="Add edit task dialog"
      handleClose={closeAddEditTaskTemplateDialog}
      onSubmit={addTask}
      isOpen={addEditTaskTemplateDialogOpened}
      title="Add Task"
      handleSaveContinue={handleSaveAndAddTask}
    />,
    <PreviewDuplicateDialog
      open={isPreviewDuplicateDialogVisible}
      name={itemToDuplicate?.Name}
      handleClose={closePreviewDuplicateDialog}
      type="item"
      onPositiveHandler={openDuplicateDialog}
    />,
    <DuplicateDialog
      key="Duplicate Dialog"
      handleClose={closeDuplicateDialog}
      itemToDuplicate={itemToDuplicate}
      onSubmit={duplicateItem}
      open={duplicateDialogOpened}
      isNewSubmitButton
      hasPadding
      withoutTopBorder
      withSubmit={false}
      hasPaddingBottom={false}
    />,
    <SensorsDialog
      key="Sensors dialog"
      title={renderSensorsDialogTitle()}
      open={sensorsDialogOpened}
      handleClose={closeSensorsDialog}
      onSubmit={() => {}}
      openLevelSettingsDialog={openLevelSettingsDialog}
      openLinkSensorDialog={openLinkSensorDialog}
      withSubmit={false}
      withoutTopBorder
    />,
    <LinkSensorDialog
      key="Link Sensor Dialog"
      handleClose={closeLinkSensorDialog}
      handleSaveContinue={handleSaveAndSetLevels}
      title="Link Air Inlet Filter with Sensor"
      open={linkSensorDialogOpened}
      onSubmit={submitLinkSensorDialog}
      saveContinueLabel="Next: Set Sensor Levels"
      getSelectedSensorId={getSelectedSensorId}
      withSaveContinue
      BigButtons
      withoutTopBorder
    />,
    <LevelSettingsDialog
      key="Level Settings Dialog"
      handleClose={closeLevelSettingsDialog}
      title="Level Settings"
      open={levelSettingsDialogOpened}
      onSubmit={submitLevelSettingsDialog}
      selectedSensorType={{
        ...selectedSensor.SensorType,
        AreLevelsDifferentFromTemplate: selectedSensor.AreLevelsDifferentFromTemplate,
      }}
      withoutTopBorder
    />,
  ];
};

ItemsList.propTypes = {
  items: PropTypes.arrayOf(UnitItemType).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      unitId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  history: PropTypes.object.isRequired,
  typeHandler: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default withRouter(ItemsList);
