import { TASK_RULE_TYPES } from 'types/task';

export const divideByDate = list => {
  const data = list.reduce(
    (result, item) => {
      const newItem = { ...item };

      newItem.ItemName = newItem.UnitName;
      newItem.UnitName = newItem.ExecutionDate;

      result.amount += 1;

      if (!result.tasks[newItem.UnitName]) {
        result.tasks[newItem.UnitName] = [];
        result.tasks[newItem.UnitName].push(newItem);
      } else {
        result.tasks[newItem.UnitName].push(newItem);
      }

      return result;
    },
    { tasks: {}, amount: 0 },
  );

  Object.keys(data.tasks).forEach(key => {
    data.tasks[key].sort(
      (a, b) => a.ItemSequence - b.ItemSequence || a.TaskOrder - b.TaskOrder || !!a.Exceptions > !!b.Exceptions,
    );
  });

  return data;
};

export const divideByUnitName = list => {
  const data = list.reduce(
    (result, item) => {
      result.amount += 1;

      if (!result.tasks[item.UnitName]) {
        result.tasks[item.UnitName] = [];
        result.tasks[item.UnitName].push(item);
      } else {
        result.tasks[item.UnitName].push(item);
      }

      return result;
    },
    { tasks: {}, amount: 0 },
  );

  Object.keys(data.tasks).forEach(key => {
    data.tasks[key].sort(
      (a, b) => a.ItemSequence - b.ItemSequence || a.TaskOrder - b.TaskOrder || !!a.Exceptions > !!b.Exceptions,
    );
  });

  return data;
};

export const prepareSaveTaskParams = ({
  actualFlow,
  actualQty,
  actualTemperature,
  feedbackResponse,
  message,
  id,
  requiresAttention,
  ruleType,
  verificationResponse,
  partsRequireAttention,
  partResolution,
  partIssueEstimationDto,
  assignedTaskParts,
  assignedTaskPartGroups,
  reportNotes,
}) => {
  const ruleTypeParams = {
    [TASK_RULE_TYPES.VERIFICATION]: {
      verificationResponse: verificationResponse || undefined,
    },
    [TASK_RULE_TYPES.REPLACE]: {
      verificationResponse: verificationResponse || undefined,
    },
    [TASK_RULE_TYPES.FEEDBACK]: { feedbackResponse },
    [TASK_RULE_TYPES.CHECK_FLOW_RATE]: { actualReading: actualFlow },
    [TASK_RULE_TYPES.CHECK_QUANTITY]: { actualReading: actualQty },
    [TASK_RULE_TYPES.CHECK_TEMPERATURE]: { actualReading: actualTemperature },
    [TASK_RULE_TYPES.PHOTO_CONFIRMATION]: {},
    [TASK_RULE_TYPES.SIGNATURE]: {},
    [TASK_RULE_TYPES.SCAN_CODE]: {},
    [TASK_RULE_TYPES.REPORT_COMPLETION]: { actualReading: actualFlow },
    [TASK_RULE_TYPES.REPORT_PROGRESS]: { actualReading: actualFlow },
    ForceComplete: { taskId: id },
  };

  const model = {
    ...ruleTypeParams[ruleType],
    id,
    reportNotes,
    requiresAttention,
    message,
    partsRequireAttention,
    partResolution,
    partIssueEstimationDto,
    assignedTaskParts,
    assignedTaskPartGroups,
  };

  return { model };
};

export const findNextTaskId = (list, currentTaskId) => {
  let foundTask = {};
  const units = Object.keys(list.tasks);

  units.some(unit =>
    list.tasks[unit].some((item, index) => {
      if (item.Id !== currentTaskId) {
        return false;
      }

      foundTask = list.tasks[unit][index + 1];
      return true;
    }),
  );

  return foundTask && foundTask.Id;
};

export const prepareSaveTaskUrl = ({ ruleType }) => {
  const ruleTypeUrl = {
    [TASK_RULE_TYPES.VERIFICATION]: 'AssignedTaskActions/CompleteVerificationTask/',
    [TASK_RULE_TYPES.REPLACE]: 'AssignedTaskActions/CompletePartReplacementTask/',
    [TASK_RULE_TYPES.FEEDBACK]: 'AssignedTaskActions/CompleteFeedbackTask/',
    [TASK_RULE_TYPES.CHECK_FLOW_RATE]: 'AssignedTaskActions/CompleteFlowRateTask/',
    [TASK_RULE_TYPES.CHECK_QUANTITY]: 'AssignedTaskActions/CompleteQuantityTask/',
    [TASK_RULE_TYPES.CHECK_TEMPERATURE]: 'AssignedTaskActions/CompleteTemperatureTask/',
    [TASK_RULE_TYPES.PHOTO_CONFIRMATION]: 'AssignedTaskActions/CompletePhotoConfirmationTask/',
    [TASK_RULE_TYPES.SIGNATURE]: 'AssignedTaskActions/CompletePhotoConfirmationTask/',
    [TASK_RULE_TYPES.SCAN_CODE]: 'AssignedTaskActions/CompleteScanCodeTask/',
    [TASK_RULE_TYPES.REPORT_COMPLETION]: 'AssignedTaskActions/CompleteReportCompletionTask',
    [TASK_RULE_TYPES.REPORT_PROGRESS]: 'AssignedTaskActions/CompleteReportProgressTask',
    ForceComplete: 'AssignedTaskActions/ForceCompleteTask/',
  };

  return ruleTypeUrl[ruleType];
};
