import styled, { css } from 'styled-components';
import { Checkbox } from 'material-ui';
import { BorderRadiusContainer, InputWrapper, InputBlockLabel } from 'components/Layout/Containers';

const Form = styled.form`
  min-width: 40vw;
  max-width: 40vw;
`;

const AddEditForm = styled.div`
  padding: 0 20px;
`;

const Instructions = styled(BorderRadiusContainer)`
  border-radius: 5px 5px 0 0;
`;

const RuleData = styled(BorderRadiusContainer)`
  border-radius: 5px 5px 0 0;
`;

const InputContainer = styled(InputWrapper)`
  padding: 10px 30px;
`;

const InstructionsLabel = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.darkGrey};
`;

const RuleDataLabel = styled(InputBlockLabel)`
  background: ${({ theme }) => theme.primaryLists};
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding: 10px 16px;
`;

const CheckBox = styled(Checkbox)`
  width: auto !important;

  & > div > div {
    margin-right: 0 !important;
    margin-left: 14px !important;
  }

  & > div > div > div > svg {
    fill: ${({ theme }) => theme.tertiaryOrange} !important;
  }
`;

const inputWrapperStyles = css`
  width: 90%;
`;

const inputStyles = css`
  border: none;
  border-radius: 0;
  border-bottom: 2px dashed ${({ theme }) => theme.primaryGrey};
`;

const inputErrorStyles = css`
  width: 80%;
  border: 2px solid ${({ theme }) => theme.mainRed};
`;

const inputWrapperErrorStyles = css`
  flex-direction: column;
  align-items: flex-start;
`;

export {
  Form,
  AddEditForm,
  Instructions,
  RuleData,
  InputContainer,
  InstructionsLabel,
  RuleDataLabel,
  Wrapper,
  CheckBox,
  inputWrapperStyles,
  inputStyles,
  inputErrorStyles,
  inputWrapperErrorStyles,
};
