import { TASK_RULE_TYPES } from 'types/task';
import { FormValuesType } from './index';

export const validate = (values: FormValuesType) => {
  const errors = {} as Record<string, string>;
  const isRequiredAttention = values.selectedSection === 'requiresAttention';

  if (isRequiredAttention && !values.taskIssue) {
    errors.taskIssue = 'Required';

    if (values.isPartsRequireAttention && !values.partResolution) {
      errors.partResolution = 'Required';
    }
  }

  if (values.ruleType === TASK_RULE_TYPES.FEEDBACK && !values.feedback && !isRequiredAttention) {
    errors.feedback = 'Required';
  }

  if (values.ruleType === TASK_RULE_TYPES.CHECK_QUANTITY && !isRequiredAttention) {
    if (values.actualQty! > values.MaxQty! || values.actualQty! < values.MinQty!) {
      errors.actualQty = 'value is out of range';
    }
  }

  if (values.ruleType === TASK_RULE_TYPES.CHECK_TEMPERATURE && !isRequiredAttention) {
    if (values.actualTemperature! > values.MaxTemperature! || values.actualTemperature! < values.MinTemperature!) {
      errors.actualTemperature = 'value is out of range';
    }
  }

  if (
    (values.ruleType === TASK_RULE_TYPES.CHECK_FLOW_RATE ||
      values.ruleType === TASK_RULE_TYPES.REPORT_COMPLETION ||
      values.ruleType === TASK_RULE_TYPES.REPORT_PROGRESS) &&
    !isRequiredAttention
  ) {
    if (values.actualFlow! > values.MaxFlow! || values.actualFlow! < values.MinFlow!) {
      errors.actualFlow = 'value is out of range';
    }
  }

  if (
    values.ruleType === TASK_RULE_TYPES.PHOTO_CONFIRMATION &&
    values.MinPhotos !== values.currentPhotos &&
    !values.currentPhotos &&
    !isRequiredAttention
  ) {
    errors.photoConfirmation = 'Nothing selected';
  }

  return errors;
};
