import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Modal } from 'components/Modal';
import SimpleList from 'components/SimpleList';
import { ActionButton, ActionsContainer } from 'components/Dialogs/v1/Base';
import PdfItem from './PdfItem';
import { findSite } from 'helpers';
import { actions as queueActions } from 'redux/queue';
import theme from 'theme';

import * as S from './styled';

const DownloadReportDialog = NiceModal.create(({ endDate, startDate, rightControls, redTitle, value, reportId }) => {
  const reportFilesList = useSelector(state => state.queue.reportFilesList);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const sitesAvailableToUserFullInfo = useSelector(state => state.auth.user.sitesAvailableToUserFullInfo);
  const modal = useModal();
  const dispatch = useDispatch();

  const site = findSite(sitesAvailableToUserFullInfo, selectedSite);

  const onDownload = () => {
    dispatch(queueActions.generateAndDownloadReportFileRequest({ id: reportId, fileFormat: value }));
    modal.hide();
  };

  return (
    <Modal
      onRequestClose={modal.hide}
      isOpen={modal.visible}
      title={`Export ${value === 'pdf' ? 'PDF' : 'Spreadsheet'}`}
      titleColor={theme.primaryDark}
    >
      <S.RootContainer>
        <S.Title>{site}</S.Title>
        <S.RedTitle>{redTitle}</S.RedTitle>
        <S.DateContainer>
          <S.TextDate>From: {startDate}</S.TextDate>
          <S.TextDate>To: {endDate}</S.TextDate>
        </S.DateContainer>
        <SimpleList
          key="Report List"
          data={reportFilesList}
          renderItemContent={PdfItem}
          renderRightControls={rightControls}
        />

        <ActionsContainer gapValue={10}>
          <ActionButton key="cancel" type="button" label="Cancel" onClick={modal.hide} />
          <ActionButton key="download" type="button" onClick={onDownload} isNext label="NEW Download" />
        </ActionsContainer>
      </S.RootContainer>
    </Modal>
  );
});

DownloadReportDialog.defaultProps = {
  endDate: null,
  startDate: null,
};

DownloadReportDialog.propTypes = {
  title: PropTypes.string.isRequired,
  endDate: PropTypes.instanceOf(Date),
  onSubmit: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  list: PropTypes.array.isRequired,
  rightControls: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  redTitle: PropTypes.string.isRequired,
};

export default DownloadReportDialog;
