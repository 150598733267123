/* eslint-disable no-shadow */
import styled, { css } from 'styled-components';
import { Slider } from 'material-ui';
import { getMuiTheme } from 'material-ui/styles';
import theme from 'theme';

const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 10px;
`;

const RangeSlider = styled(Slider)`
  width: 100%;

  div > div:not(:last-child) {
    border-radius: 6px;
  }
`;

const inputWrapperStyles = css`
  flex-direction: column;
`;

const inputStyles = css`
  padding: 0.4rem;
  border: 1px solid ${({ theme }) => theme.primaryTasks};
  color: ${({ theme }) => theme.mainRed};
  width: 50%;
  font-weight: bold;
  font-size: 1.2rem;
`;

const errorStyles = css`
  border: 1px solid ${({ theme }) => theme.mainRed};
`;

const muiTheme = getMuiTheme({
  slider: {
    trackColor: theme.primaryGrey,
    selectionColor: theme.primaryTasks,
    handleColorZero: theme.primaryTasks,
    handleFillColor: theme.primaryTasks,
    handleSize: 24,
    handleSizeActive: 24,
    trackSize: 6,
    rippleColor: theme.primaryTasks,
  },
});

export { SliderContainer, RangeSlider, inputWrapperStyles, inputStyles, errorStyles, muiTheme };
