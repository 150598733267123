import React from 'react';
import PropTypes from 'prop-types';

import DialogTitle from './DialogTitle';

import { MaterialDialog, ButtonsContainer, StyledButton } from './styled';

import theme from 'theme';

export const Dialog = ({
  children,
  title,
  noCapitalize,
  zIndex,
  titleColor,
  onRequestClose,
  centeredTitle,
  ...rest
}) => (
  <MaterialDialog
    onRequestClose={onRequestClose}
    autoDetectWindowHeight
    zIndex={zIndex}
    title={
      <DialogTitle
        title={title}
        titleColor={titleColor}
        handleClose={onRequestClose}
        noCapitalize={noCapitalize}
        centeredTitle={centeredTitle}
      />
    }
    {...rest}
  >
    {children}
  </MaterialDialog>
);

export const ActionsContainer = ({ children, isColumn, paddingValue, marginValue, gapValue }) => (
  <ButtonsContainer isColumn={isColumn} paddingValue={paddingValue} marginValue={marginValue} gapValue={gapValue}>
    {children}
  </ButtonsContainer>
);

export const ActionButton = ({
  isNext,
  isCancelNext,
  isDisable,
  isGreenLabel,
  label,
  onClick,
  type,
  isColumn,
  withBorder,
  withGreenBorder,
  disabled,
  labelFontSize,
}) => (
  <StyledButton
    onClick={onClick}
    label={label}
    type={type}
    isNext={isNext}
    isDisable={isDisable}
    isGreenLabel={isGreenLabel}
    isCancelNext={isCancelNext}
    isColumn={isColumn}
    withBorder={withBorder}
    withGreenBorder={withGreenBorder}
    disabled={disabled}
    labelFontSize={labelFontSize}
  />
);

Dialog.defaultProps = {
  onRequestClose: () => {},
  title: theme.headerColor,
  titleColor: theme.headerColor,
  noCapitalize: false,
  centeredTitle: null,
  tooltipText: '',
};

Dialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  titleColor: PropTypes.string,
  zIndex: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noCapitalize: PropTypes.bool,
  tooltipText: PropTypes.string,
  centeredTitle: PropTypes.func,
};

ActionsContainer.defaultProps = {
  isColumn: false,
  paddingValue: 0,
  marginValue: 0,
  gapValue: 0,
};

ActionsContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]).isRequired,
  isColumn: PropTypes.bool,
  paddingValue: PropTypes.number,
  marginValue: PropTypes.number,
  gapValue: PropTypes.number,
};

ActionButton.propTypes = {
  isNext: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelFontSize: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  isColumn: PropTypes.bool,
  withBorder: PropTypes.bool,
  withGreenBorder: PropTypes.bool,
  isCancelNext: PropTypes.bool,
  disabled: PropTypes.bool,
  isDisable: PropTypes.bool,
  isGreenLabel: PropTypes.bool,
};

ActionButton.defaultProps = {
  type: '',
  labelFontSize: '',
  isNext: false,
  isColumn: false,
  withBorder: false,
  withGreenBorder: false,
  isCancelNext: false,
  disabled: false,
  isDisable: false,
  isGreenLabel: false,
  onClick: () => {},
};
