/* eslint-disable no-shadow */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import theme from 'theme';

import { openErrorDialog } from 'redux/errorHandler';
import { openConfirmationDialog } from 'redux/confirmationHandler';
import { actions as itemActions } from 'redux/item';
import { actions as unitActions } from 'redux/unit';
import { getItemsIds } from 'redux/unit/selectors';
import { getQRSource } from 'http/unit';
import { getImageSource } from 'http/item';

import Footer from 'components/Footer';
import DuplicateDialog from 'components/Dialogs/DuplicateDialog';
import AddEditTaskTemplateDialog from '../ItemsList/AddEditTaskTemplateDialog';

import DragItemsListDialog from '../../UnitsList/DragItemsListDialog';
import TopBar from '../TopBar';
import { SequenceSetupIcon } from '../styled';

import Tasks from './Tasks';
import Details from './Details';
import * as S from './styled';

const ItemDetails = ({
  history,
  match: {
    params: { unitId, itemId },
  },
}) => {
  const [addEditTaskDialogOpened, setAddEditTaskDialogOpened] = useState(false);
  const [saveAndAddTaskSelected, setSaveAndAddTaskSelected] = useState(false);
  const [withSaveContinueTaskModal, setWithSaveContinueTaskModal] = useState(true);
  const [isDuplicateDialogOpened, setIsDuplicateDialogOpened] = useState(false);
  const [dragFullListDialogOpened, setDragFullListDialogOpened] = useState(false);
  const [duplicationTaskType, setDuplicationTaskType] = useState('');
  const [duplicationTaskId, setDuplicationTaskId] = useState('');
  const [taskToEdit, setTaskToEdit] = useState({});
  const [type, setType] = useState('tasks');
  const [searchData, setSearchData] = useState('');

  const details = useSelector(state => state.item.details);
  const tasks = useSelector(state => state.item.tasks);
  const pictures = useSelector(state => state.item.pictures);
  const pdfs = useSelector(state => state.item.pdfs);
  const error = useSelector(state => state.item.error);
  const newAddedTask = useSelector(state => state.item.newAddedTask);
  const itemsIds = useSelector(getItemsIds);
  const dispatch = useDispatch();

  const goToItemsList = () => {
    dispatch(unitActions.fetchUnitItemsRequest({ unitId: details.UnitId }));

    history.push(`/units/items/${unitId}`);
  };

  const uploadItemPicture = files => {
    files.forEach(file => {
      dispatch(
        itemActions.uploadPictureRequest({
          itemId: details.Id,
          file,
        }),
      );
    });
  };

  const onDeleteTask = () => {
    dispatch(
      openConfirmationDialog(
        'Are you sure you want to permanently delete this Task? This cannot be undone!',
        onDeleteTaskApprove,
        'Delete?',
      ),
    );
  };

  const onDeleteTaskApprove = () => {
    closeAddEditTaskDialog();

    dispatch(
      itemActions.deleteTaskRequest({
        itemId: details.Id,
        taskId: taskToEdit.task.Id,
      }),
    );
  };

  const openAddEditTaskDialog = useCallback(
    (itemId, task) => {
      let data;
      if (task?.Id) {
        data = tasks.find(item => item.Id === task.Id);
      }
      setAddEditTaskDialogOpened(true);
      setTaskToEdit({
        task: task.Id ? data : null,
        itemId,
      });
      setWithSaveContinueTaskModal(!task.Id);
    },
    [tasks],
  );

  const onDeleteItemPdf = (pdfId, pdfName) => {
    dispatch(
      openConfirmationDialog(
        'Are you sure you want to permanently delete this PDF?',
        () =>
          dispatch(
            itemActions.deletePdfRequest({
              pdfId,
              pdfName,
              itemId: details.Id,
            }),
          ),
        'Delete PDF?',
      ),
    );
  };

  const onDeleteItemPicture = (pictureId, pictureName) => {
    dispatch(
      openConfirmationDialog(
        'Are you sure you want to permanently delete this Photo?',
        () =>
          dispatch(
            itemActions.deletePictureRequest({
              pictureId,
              pictureName,
              itemId: details.Id,
            }),
          ),
        'Delete Photo?',
      ),
    );
  };

  const uploadItemPdf = files => {
    files.forEach(file => {
      dispatch(
        itemActions.uploadPdfRequest({
          itemId: details.Id,
          file,
        }),
      );
    });
  };

  const closeAddEditTaskDialog = () => setAddEditTaskDialogOpened(false);

  const handleCloseAddEditTaskDialog = () => {
    closeAddEditTaskDialog();
    openAddEditTaskDialogOnModalClose();
  };

  const openAddEditTaskDialogOnModalClose = () => {
    if (saveAndAddTaskSelected) {
      setTimeout(() => {
        // need time for modal animations
        setSaveAndAddTaskSelected(false);
        setAddEditTaskDialogOpened(true);
      }, 500);
    } else {
      setWithSaveContinueTaskModal(false);
    }
  };

  const addTask = data => {
    dispatch(
      itemActions.addTaskRequest({
        itemId: details.Id,
        data,
        onSuccess: handleCloseAddEditTaskDialog,
      }),
    );
  };

  const editTask = data => {
    dispatch(
      itemActions.editTaskRequest({
        itemId: details.Id,
        data,
        onSuccess: closeAddEditTaskDialog,
      }),
    );
  };

  const handleSaveAndAddTask = () => {
    setWithSaveContinueTaskModal(true);
    setSaveAndAddTaskSelected(true);
  };

  const onNextItem = () => {
    const nextItemIndex = itemsIds.indexOf(itemId) + 1;
    const index = nextItemIndex > itemsIds.length - 1 ? 0 : nextItemIndex;
    history.push(`../details/${itemsIds[index]}`);
  };

  const onPrevItem = () => {
    const prevItemIndex = itemsIds.indexOf(itemId) - 1;
    const index = prevItemIndex < 0 ? itemsIds.length - 1 : prevItemIndex;
    history.push(`../details/${itemsIds[index]}`);
  };

  const toggleDuplicateDialog = task => {
    setIsDuplicateDialogOpened(!isDuplicateDialogOpened);
    setDuplicationTaskType(task.RuleType || '');
    setDuplicationTaskId(task.Id || '');
  };

  const openDragFullListDialog = () => {
    setDragFullListDialogOpened(!dragFullListDialogOpened);
  };

  const prepareTasksDragListContent = () => [
    {
      content: details.Name,
      id: details.Id,
      isStrictOrder: details.IsStrictOrder,
      items: tasks.map((task, i) => ({
        id: task.Id,
        content: task.RuleType,
        sequence: i + 1,
        tooltipTitle: task.Name,
      })),
    },
  ];

  const typeHandler = status => setType(status.key);

  const exportQR = () => getQRSource(itemId);

  const searchInList = event => setSearchData(event.target.value);

  const rightButtons = [
    {
      icon: <SequenceSetupIcon />,
      hint: 'Tasks',
      handler: openDragFullListDialog,
      tooltipStyles: { marginTop: '-10px' },
    },
  ];

  const updateTasksList = values => dispatch(unitActions.updateTasksListRequest({ values, itemId: details.Id }));
  const duplicateTask = () => {
    dispatch(itemActions.duplicateTaskRequest({ params: { itemId: details.Id, taskId: duplicationTaskId } }));
  };

  useEffect(() => {
    if (!itemsIds.length) {
      dispatch(unitActions.fetchUnitItemsRequest({ unitId }));
    }
  }, [dispatch, itemsIds.length, unitId]);

  useEffect(() => {
    dispatch(itemActions.fetchItemDetailsRequest(itemId));
  }, [dispatch, itemId]);

  useEffect(() => {
    if (!isEmpty(newAddedTask) && isDuplicateDialogOpened) {
      setIsDuplicateDialogOpened(false);
      if (tasks.length) {
        openAddEditTaskDialog(details.Id, newAddedTask);
      }
    }
  }, [details.Id, isDuplicateDialogOpened, openAddEditTaskDialog, tasks, newAddedTask]);

  useEffect(() => {
    if (error) {
      dispatch(openErrorDialog(error, 'Error'));
    }
  }, [dispatch, error]);

  return (
    <S.Container>
      <TopBar
        type={type}
        key="Item Details Bar"
        searchData={searchData}
        searchInList={searchInList}
        rightButtons={rightButtons}
        title={
          <>
            <S.RedTitle>{details.Name}</S.RedTitle>
            <S.UnitName>{details.UnitName}</S.UnitName>
          </>
        }
        goBack={goToItemsList}
      />
      {type === 'tasks' ? (
        <Tasks
          itemId={details.Id}
          onAddEditTaskTemplateClick={openAddEditTaskDialog}
          tasks={tasks}
          onCopyTask={toggleDuplicateDialog}
          openDragFullListDialog={openDragFullListDialog}
          type={type}
          typeHandler={typeHandler}
          searchData={searchData}
        />
      ) : (
        <Details
          uploadUnitPdf={uploadItemPdf}
          pdfs={pdfs}
          exportUnitQR={exportQR}
          pictures={pictures}
          item={details}
          type={type}
          getImageSource={getImageSource}
          onDeleteUnitPicture={onDeleteItemPicture}
          uploadUnitPicture={uploadItemPicture}
          typeHandler={typeHandler}
          onDeleteUnitPdf={onDeleteItemPdf}
          exportUnitPdf={uploadItemPicture}
        />
      )}
      <Footer
        key="Item Details Footer"
        label="Item"
        onNext={onNextItem}
        onPrev={onPrevItem}
        isDisabled={itemsIds.length <= 1}
        iconColor={theme.primaryLists}
      />
      <AddEditTaskTemplateDialog
        handleClose={closeAddEditTaskDialog}
        handleDelete={onDeleteTask}
        handleSaveContinue={handleSaveAndAddTask}
        isOpen={addEditTaskDialogOpened}
        onSubmit={taskToEdit.task ? editTask : addTask}
        title={taskToEdit.task ? 'Edit Task' : 'Add Task'}
        task={taskToEdit.task || undefined}
        withSaveContinue={withSaveContinueTaskModal}
      />
      <DuplicateDialog
        open={isDuplicateDialogOpened}
        name={duplicationTaskType}
        handleClose={toggleDuplicateDialog}
        type="task"
        onPositiveHandler={duplicateTask}
      />
      {tasks.length && dragFullListDialogOpened ? (
        <DragItemsListDialog
          key="DragItemsList Dialog"
          handleClose={openDragFullListDialog}
          units={prepareTasksDragListContent()}
          onSubmit={updateTasksList}
          open={dragFullListDialogOpened}
          listId="ItemsTasks"
          title="Sequence Setup (drag tasks to order)"
          commonLockHide
        />
      ) : null}
    </S.Container>
  );
};

ItemDetails.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.object.isRequired,
};

export default ItemDetails;
